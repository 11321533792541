<template>
  <b-card class="admin-knowledge-base-questions">
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="knowledgeBaseQuestions"
          :button-label="$t('Add Questions')"
          :columns="questionsColumns"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="'/admin/knowledge/questions'"
          @add-new-info="showAddModal"
        />
      </b-col>
    </b-row>
    <b-modal
      id="knowledgebaseCategoryQuestions"
      v-model="showQuestionsModal"
      :no-close-on-backdrop="true"
      :title="dataInfo && dataInfo.id ? 'Edit Knowledge Base Questions' : 'Add Knowledge Base Questions'"
      size="lg"
      ok-only
      :ok-title="$t('Save Changes')"
      @hidden="showQuestionsModal = false"
      @ok="saveData"
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group :label="$t('Question')">
            <b-form-input
              v-model="dataInfo.question"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Status')">
            <b-form-select
              v-model="dataInfo.status"
              :options="[
                {
                  text: 'Active',
                  value: '1',
                },
                {
                  text: 'Inactive',
                  value: '0',
                },
              ]"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Category')">
            <b-form-select
              v-model="dataInfo.category_id"
              :options="knowledgeCategoryOptions"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group :label="$t('Answers')">
            <quill-editor
              v-model="dataInfo.answer"
              :options="snowOption"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormGroup, BFormInput, BFormSelect, BModal } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { quillEditor } from 'vue-quill-editor'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useAdminUi } from '../useAdmin'

export default {
  components: {
    GoodDataTable,
    quillEditor,
    BCard,
    BRow,
    BModal,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      questionsColumns: [
        {
          label: 'Category',
          field: 'category_id',
          rField: 'category_id_text',
          useResolver: true,
          useRenderer: true,
          renderer: props => {
            if (this.knowledgeCategoryOptions.length) {
              const isExist = this.knowledgeCategoryOptions.find(x => x.value === props.category_id)
              if (isExist) {
                return isExist.text
              }
            }
            return ''
          },
        },
        {
          label: 'Question',
          field: 'question',
        },
        {
          label: 'Status',
          field: 'status',
          rField: 'status_text',
          align: 'center',
          useResolver: true,
          useRenderer: true,
          renderer: props => (props.status === 1 ? 'Active' : 'Inactive'),
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.editQuestions(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('status'),
              text: 'Change Status',
              action: props => {
                this.$swal({
                  title: this.$t('You want to change the status?'),
                  iconHtml: this.$helpers.swalIcon('confirm.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.changeQuestionStatus(props)
                  }
                })
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete this question?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.deleteQuestions(props)
                  }
                })
              },
            },
          ],
        },
      ],
      knowledgeCategoryOptions: [],
      showQuestionsModal: false,
      dataInfo: {
        answer: '',
        category_id: '',
        id: '',
        question: '',
        status: '',
      },
    }
  },
  mounted() {
    this.getKnowledgeCategories()
  },
  methods: {
    getKnowledgeCategories() {
      const { getKnowledgeCategories } = useAdminUi()
      getKnowledgeCategories()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.knowledgeCategoryOptions = [
              ...[
                {
                  text: 'Select Category',
                  value: '',
                },
              ],
              ...data.responseData.map(x => ({
                text: x.title,
                value: x.id,
              })),
            ]
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    showAddModal() {
      Object.keys(this.dataInfo).forEach(x => {
        this.dataInfo[x] = ''
      })
      this.showQuestionsModal = true
    },
    updateQuestions() {
      const { updateKnowledgeBaseQuestion } = useAdminUi()
      showLoader()
      const formData = this.getFormData()
      // eslint-disable-next-line no-underscore-dangle
      formData._method = 'PATCH'
      updateKnowledgeBaseQuestion({
        query: this.dataInfo.id,
        data: formData,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.knowledgeBaseQuestions.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            this.showQuestionsModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    saveData() {
      if (this.dataInfo.id) {
        this.updateQuestions()
      } else {
        this.addQuestions()
      }
    },
    getFormData() {
      const dataInfo = window.SuperApp.actions.cloneDeep(this.dataInfo)
      Object.keys(dataInfo).forEach(x => {
        if (['status'].includes(x)) {
          dataInfo[x] = Number(dataInfo[x])
        }
      })
      return dataInfo
    },
    addQuestions() {
      const { createKnowledgeBaseQuestions } = useAdminUi()
      showLoader()
      const formData = this.getFormData()
      createKnowledgeBaseQuestions(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.knowledgeBaseQuestions.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            this.showQuestionsModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          this.showQuestionsModal = true
          showErrorNotification(this, error)
        })
    },
    editQuestions(props) {
      showLoader()
      const { getKnowledgeBaseQuestionsById } = useAdminUi()

      getKnowledgeBaseQuestionsById(props.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            const questions = data.responseData
            Object.keys(this.dataInfo).forEach(x => {
              this.dataInfo[x] = questions[x]
            })
            this.showQuestionsModal = true
          }
          hideLoader()
        })
        .catch(error => {
          this.showQuestionsModal = true
          showErrorNotification(this, error)
        })
    },
    deleteQuestions(props) {
      showLoader()
      const { deleteKnowledgeBaseQuestions } = useAdminUi()

      deleteKnowledgeBaseQuestions(props.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.knowledgeBaseQuestions.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            this.showQuestionsModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          this.showQuestionsModal = true
          showErrorNotification(this, error)
        })
    },
    changeQuestionStatus(props) {
      const payload = window.SuperApp.actions.cloneDeep(props)
      payload.status = payload.status === 0 ? 1 : 0
      this.dataInfo = payload
      this.updateQuestions()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

#knowledgebaseCategoryQuestions .ck.ck-editor__top.ck-reset_all {
  display: block !important;
}
</style>
